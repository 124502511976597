import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAdventContests } from "../../features/competition/competitionSlice";
import { Link, useNavigate } from "react-router-dom";

const WeekSelector = ({ weeks, activeWeek, onSelectWeek }) => (
  <div className="w-full overflow-x-auto mb-8">
    <div className="flex space-x-4 min-w-max p-4">
      {weeks.map((week) => (
        <button
          key={week.id}
          onClick={() => onSelectWeek(week.id)}
          className={`px-6 py-3 rounded-lg transition-colors duration-300 ${
            activeWeek === week.id
              ? "bg-blue-600 text-white"
              : "bg-zinc-800 text-blue-300 hover:bg-zinc-700"
          }`}
        >
          {week.name}
        </button>
      ))}
    </div>
  </div>
);
const getSubmissionStatusBadge = (submission) => {
  if (!submission) {
    return (
      <div className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-zinc-800/80 text-zinc-400 ring-1 ring-zinc-700/50">
        New
      </div>
    );
  }

  const statusConfig = {
    Accepted: {
      bgColor: "bg-green-950/30",
      textColor: "text-green-400",
      ringColor: "ring-green-500/20",
    },
    "In Progress": {
      bgColor: "bg-yellow-950/30",
      textColor: "text-yellow-400",
      ringColor: "ring-yellow-500/20",
    },
    Failed: {
      bgColor: "bg-red-950/30",
      textColor: "text-red-400",
      ringColor: "ring-red-500/20",
    },
  };

  const config = statusConfig[submission.status] || {
    bgColor: "bg-blue-950/30",
    textColor: "text-blue-400",
    ringColor: "ring-blue-500/20",
  };

  return (
    <div className="space-y-1.5">
      <div
        className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ring-1 ${config.bgColor} ${config.textColor} ${config.ringColor}`}
      >
        {submission.status}
      </div>
      {submission.attemptCounter > 0 && (
        <div className="text-xs text-zinc-400 font-medium">
          {submission.attemptCounter} attempts
        </div>
      )}
    </div>
  );
};

const ChallengeGrid = ({ challenges, contestTag }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const handleChallengeClick = (challengeSlug) => {
    if (!user.length) return;
    navigate(`/advent/${contestTag}/${challengeSlug}`);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {challenges.map((challenge) => (
        <div key={challenge._id} className="group relative">
          <div
            onClick={() => handleChallengeClick(challenge.slug)}
            className={`relative bg-gradient-to-b from-blue-950/40 to-blue-900/10 backdrop-blur-sm p-6 rounded-xl 
              border border-blue-500/10 hover:border-blue-500/20 transition-all duration-300 
              flex flex-col h-64 shadow-lg hover:shadow-blue-900/5
              ${
                user.length ? "cursor-pointer" : "cursor-not-allowed opacity-80"
              }`}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleChallengeClick(challenge.slug);
              }
            }}
          >
            <div className="flex flex-col h-full">
              {/* Header Section */}
              <div className="flex justify-between items-start gap-4 mb-4">
                <h3 className="text-lg font-semibold bg-gradient-to-br from-blue-200 to-blue-400 bg-clip-text text-transparent">
                  {challenge.name}
                </h3>
                <div className="flex-shrink-0">
                  {getSubmissionStatusBadge(challenge.userSubmission)}
                </div>
              </div>

              {/* Stats Section - Now uses flex-grow to fill space */}
              <div className="flex-grow flex flex-col justify-end">
                <div className="flex items-baseline gap-4 border-t border-blue-500/10 pt-4">
                  <div className="flex-1">
                    <div className="text-sm font-medium text-zinc-400">
                      Available Points
                    </div>
                    <div className="text-xl font-semibold text-blue-300 mt-0.5">
                      {challenge.points}
                    </div>
                  </div>

                  {challenge.userSubmission?.pointsEarned > 0 && (
                    <div>
                      <div className="text-sm font-medium text-zinc-400">
                        Earned
                      </div>
                      <div className="text-xl font-semibold text-green-400 mt-0.5">
                        {challenge.userSubmission.pointsEarned}
                      </div>
                    </div>
                  )}
                </div>

                {challenge.userSubmission?.lastSubmissionDate && (
                  <div className="text-xs text-zinc-500 mt-3 font-medium truncate">
                    Last attempt:{" "}
                    {new Date(
                      challenge.userSubmission.lastSubmissionDate
                    ).toLocaleDateString()}
                  </div>
                )}
              </div>
            </div>

            {/* Hover Effect Overlay */}
            <div className="absolute inset-0 rounded-xl ring-1 ring-inset ring-blue-500/20 group-hover:ring-blue-500/30 transition-all duration-300" />
          </div>

          {/* Login Tooltip */}
          {!user.length && (
            <div
              className="absolute -top-12 left-1/2 transform -translate-x-1/2 w-72 opacity-0 
                          group-hover:opacity-100 transition-all duration-200 pointer-events-none z-10"
            >
              <div className="bg-zinc-800 text-blue-200 text-sm py-2 px-4 rounded-lg shadow-xl border border-blue-500/20">
                <div className="text-center">
                  Login to complete the challenge
                </div>
                <div className="absolute left-1/2 transform -translate-x-1/2 translate-y-2">
                  <div className="border-8 border-transparent border-t-zinc-800" />
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
const LoadingState = () => (
  <div className="flex items-center justify-center h-64">
    <div className="text-blue-400">Loading challenges...</div>
  </div>
);

const ErrorState = ({ error }) => (
  <div className="flex items-center justify-center h-64">
    <div className="text-red-400">Error loading challenges: {error}</div>
  </div>
);

const ChallengesSection = () => {
  const [activeWeek, setActiveWeek] = useState(1);
  const dispatch = useDispatch();

  const { adventContests, showSpinner, generalError } = useSelector(
    (state) => state.competitions
  );

  React.useEffect(() => {
    dispatch(getAdventContests());
  }, [dispatch]);

  if (showSpinner) return <LoadingState />;
  if (generalError) return <ErrorState error={generalError.Error} />;

  // Transform advent contests into weeks format
  const weeks =
    adventContests?.map((contest, index) => ({
      id: index + 1,
      name: contest.name,
      challenges: contest.challenges || [],
      tag: contest.tag, // Add tag from the contest data
    })) || [];

  // Get current week's challenges and tag
  const currentWeek = weeks.find((week) => week.id === activeWeek);
  const currentWeekChallenges = currentWeek?.challenges || [];
  const currentContestTag = currentWeek?.tag;

  if (!adventContests?.length) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="text-blue-400">
          Challenges will begin to drop on December 1st!
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Development Challenges</h2>
      <WeekSelector
        weeks={weeks}
        activeWeek={activeWeek}
        onSelectWeek={setActiveWeek}
      />
      <ChallengeGrid
        challenges={currentWeekChallenges}
        contestTag={currentContestTag}
      />
    </div>
  );
};

export default ChallengesSection;
