import React, { useEffect } from "react";
import moment from "moment";
import { X } from "lucide-react";

const SuccessNotification = ({
  executionStatus,
  showNotification,
  onClose,
}) => {
  useEffect(() => {
    if (
      executionStatus?.submissionResults?.status === "Accepted" &&
      showNotification
    ) {
      const timer = setTimeout(onClose, 10000);
      return () => clearTimeout(timer);
    }
  }, [executionStatus?.submissionResults?.status, showNotification, onClose]);

  if (
    !showNotification ||
    executionStatus?.submissionResults?.status !== "Accepted"
  ) {
    return null;
  }

  const {
    pointsEarned,
    attemptCounter,
    firstSubmitionDate,
    lastSubmissionDate,
  } = executionStatus?.submissionResults ?? {};

  return (
    <div className="fixed inset-x-0 bottom-20 mx-auto z-50 flex justify-center">
      <div className="rounded-lg shadow-lg p-4 bg-green-800 text-white relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 p-1 hover:bg-green-700 rounded-full transition-colors"
          aria-label="Close notification"
        >
          <X size={16} />
        </button>
        <div className="flex flex-col items-center space-y-2">
          <h3 className="text-lg font-bold">Challenge Completed! 🎉</h3>
          <p className="text-white/90">
            You earned{" "}
            <span className="font-bold text-yellow-300">
              {pointsEarned} points
            </span>
            !
          </p>
          <p className="text-sm text-white/75">Attempts: {attemptCounter}</p>
          <p className="text-sm text-white/75">
            First Attempt:{" "}
            {moment
              .utc(firstSubmitionDate)
              .local()
              .format("MMMM D, YYYY h:mm A")}
          </p>
          <p className="text-sm text-white/75">
            Last Attempt:{" "}
            {moment
              .utc(lastSubmissionDate)
              .local()
              .format("MMMM D, YYYY h:mm A")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuccessNotification;
