import React, { useEffect, useState } from "react";

const GingerbreadAnimation = ({ show, duration = 8000 }) => {
  const [particles, setParticles] = useState([]);
  const burstDuration = 3000;
  const fallDuration = 5000;

  useEffect(() => {
    if (show) {
      const newParticles = Array.from({ length: 100 }, () => ({
        id: Math.random(),
        angle: Math.random() * Math.PI * 2,
        velocity: 1 + Math.random() * 4,
        opacity: 0.3 + Math.random() * 0.7,
        size: 12 + Math.random() * 8,
        fallDelay: Math.random() * 1000,
        x:
          Math.cos(Math.random() * Math.PI * 2) * (1 + Math.random() * 4) * 150,
        y:
          Math.sin(Math.random() * Math.PI * 2) * (1 + Math.random() * 4) * 150,
      }));

      setParticles(newParticles);
      const timer = setTimeout(() => setParticles([]), duration);
      return () => clearTimeout(timer);
    }
  }, [show, duration]);

  return (
    <div className="fixed inset-0 pointer-events-none z-50">
      {particles.map(({ id, x, y, opacity, size, fallDelay }) => (
        <div
          key={id}
          className="absolute text-blue-100 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-burst-fall"
          style={{
            opacity,
            fontSize: `${size}px`,
            "--x": `${x}px`,
            "--y": `${y}px`,
            "--burst-duration": `${burstDuration}ms`,
            "--fall-duration": `${fallDuration}ms`,
            "--fall-delay": `${burstDuration + fallDelay}ms`,
          }}
        >
          ❄
        </div>
      ))}
      <style>{`
        .animate-burst-fall {
          animation: 
            burst var(--burst-duration) cubic-bezier(0.16, 1, 0.3, 1) forwards,
            fall var(--fall-duration) linear var(--fall-delay) forwards;
          will-change: transform;
        }
        @keyframes burst {
          to { transform: translate(var(--x), var(--y)); }
        }
        @keyframes fall {
          from { transform: translate(var(--x), var(--y)); }
          to { transform: translate(var(--x), calc(100vh + 50px)); }
        }
      `}</style>
    </div>
  );
};

export default GingerbreadAnimation;
