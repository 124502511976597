import React from "react";
import {
  GiPresent,
  GiStarsStack,
  GiCalendar,
  GiTrophyCup,
} from "react-icons/gi";
import { FaCode, FaHeart } from "react-icons/fa";

const Welcome = () => {
  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold mb-4 flex items-center gap-3">
        <GiStarsStack className="text-blue-300" size={28} />
        What is this?
      </h2>

      <div className="grid gap-6">
        <div className="bg-zinc-800/50 rounded-xl p-6 border border-blue-500/20 backdrop-blur-sm">
          <p className="text-lg text-blue-100">
            It's a 25-day event featuring a series of holiday-themed{" "}
            <span className="text-blue-300 font-semibold">Salesforce</span>{" "}
            development challenges.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-4">
          <div className="bg-zinc-800/30 rounded-xl p-6 border border-blue-500/10 hover:border-blue-500/30 transition-all">
            <div className="flex items-center gap-3 mb-3">
              <GiCalendar className="text-blue-300" size={24} />
              <h3 className="text-lg font-semibold text-blue-200">
                Daily Challenges
              </h3>
            </div>
            <p className="text-gray-300">
              From December 1st through the 25th, at{" "}
              <strong>9:00 AM EST</strong>, follow along with the storyline to
              solve new Salesforce challenges. New missions drop at midnight to
              help save the villagers!
            </p>
          </div>

          <div className="bg-zinc-800/30 rounded-xl p-6 border border-blue-500/10 hover:border-blue-500/30 transition-all">
            <div className="flex items-center gap-3 mb-3">
              <FaCode className="text-blue-300" size={24} />
              <h3 className="text-lg font-semibold text-blue-200">
                Weekly Themes
              </h3>
            </div>
            <p className="text-gray-300">
              Apply different skills each week:{" "}
              <span className="text-blue-300 font-medium">
                Apex, Flow, Validation Rules, and SOQL
              </span>
            </p>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-4">
          <div className="bg-zinc-800/30 rounded-xl p-6 border border-blue-500/10 hover:border-blue-500/30 transition-all">
            <div className="flex items-center gap-3 mb-3">
              <GiTrophyCup className="text-yellow-400" size={24} />
              <h3 className="text-lg font-semibold text-blue-200">
                Earn Points
              </h3>
            </div>
            <p className="text-gray-300">
              Complete challenges to earn points and secure your spot on the
              leaderboard. Show off what you can do!
            </p>
          </div>

          {/* Charity Card */}
          <div className="bg-zinc-800/30 rounded-xl p-6 border border-blue-500/10 hover:border-blue-500/30 transition-all">
            <div className="flex items-center gap-3 mb-3">
              <FaHeart className="text-red-400" size={24} />
              <h3 className="text-lg font-semibold text-blue-200">
                Feed the Hungry
              </h3>
            </div>
            <p className="text-gray-300">
              We're raising money for charity to help real folks get through the
              holidays. $1 = 3 meals for those in need.
            </p>
          </div>
        </div>

        <div className="bg-gradient-to-r from-blue-600/20 via-purple-600/20 to-blue-600/20 rounded-xl p-6 text-center">
          <p className="text-lg text-blue-100">
            Get into the holiday spirit and keep your dev skills sharp! Good
            luck & have fun!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
