import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Split from "react-split";
import Confetti from "react-confetti";
import { getAdventChallenge } from "../../features/competition/competitionSlice";

import CodeEditor from "./CodeEditor";
import ResultPanel from "./ResultPanel";
import CompetitionFooter from "./CompetitionFooter";
import GingerbreadAnimation from "./GingerbreadAnimation";
import SuccessNotification from "./SuccessNotification";

import CompetitionMarkdown from "./CompetitionMarkdown";
import Spinner from "../../shared/Spinner";
import noGood from "../../../assets/noGood.jpeg";
import DisplayNameCheck from "../Advent/DisplayNameCheck";

const AdventChallenge = () => {
  const { tag, slug } = useParams();
  const dispatch = useDispatch();
  const [showConfetti, setShowConfetti] = useState(false);
  const [showSuccess, setShowSuccess] = useState(true);
  const [showNotification, setShowNotification] = useState(false);

  const {
    challenge,
    showSpinner,
    generalError,
    contestCompleted,
    submissionHistory,
    executionStatus,
  } = useSelector((state) => state.competitions);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // console.log({
    //   executionStatus,
    //   showNotification,
    //   challenge: challenge?.[0]?.submissions?.[0],
    // });

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (tag && slug) {
      dispatch(getAdventChallenge({ tag, slug }));
    }
  }, [tag, slug, dispatch]);

  useEffect(() => {
    if (executionStatus?.data?.exceptionMessage) {
      try {
        const results = JSON.parse(
          executionStatus.data.exceptionMessage.replace(
            "TestResultException: ",
            ""
          )
        );
        const allPassed = results.every((test) => test.pass === true);
        if (allPassed) {
          setShowConfetti(true);
          setShowNotification(true);
          setTimeout(() => setShowConfetti(false), 1500);
        }
      } catch (e) {
        console.error("Error parsing test results:", e);
      }
    }
  }, [executionStatus]);

  if (generalError) {
    return (
      <div className="h-screen flex items-center justify-center bg-zinc-950">
        <div className="text-center text-xl bg-zinc-800 border border-black px-32 py-24">
          <p className="">{generalError.Error}</p>
          {generalError.ShowIMG && (
            <img src={noGood} alt="fireSpot" className="h-64 py-4" />
          )}
          {generalError.FixRoute && (
            <Link
              to={generalError.FixRoute}
              className="text-base-300 underline hover:text-yellow-500"
            >
              {generalError?.FixLabel}
            </Link>
          )}
        </div>
      </div>
    );
  }

  const renderConfetti = () => (
    <>
      <Confetti width={windowSize.width} height={windowSize.height} />
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 backdrop-blur-[2px] backdrop-brightness-20">
        <div className="text-sm text-white bg-zinc-900 py-14 px-32 border-white border text-center">
          <p className="pb-2">
            <strong className="text-yellow-400">
              {contestCompleted.name} - {contestCompleted.type}
            </strong>
          </p>
          <p className="pb-2">
            Way to go, {contestCompleted.displayName}! <br />
            <br /> You completed all of this week's challenges and helped save
            the Gingerbread Village!
          </p>
          <p className="pb-2">
            Challenges Completed:{" "}
            <strong className="text-yellow-400">
              {contestCompleted.totalChallenges}
            </strong>
          </p>
          <p className="pb-2">
            Points Earned:{" "}
            <strong className="text-yellow-400">
              {contestCompleted.totalPointsEarned}
            </strong>
          </p>
          <div className="flex items-center justify-center pt-5">
            <Link
              to="/advent-of-salesforce"
              className="btn btn-wide btn-sm btn-primary px-48 whitespace-nowrap"
            >
              Head Back Home
            </Link>
          </div>
        </div>
      </div>
    </>
  );

  const renderEditorlessLayout = () => (
    <div className="h-[calc(100%-64px)]">
      <Split
        className="h-full splitHor"
        minSize={600}
        gutterSize={8}
        snapOffset={1}
        dragInterval={1}
      >
        <CompetitionMarkdown challenge={challenge} />
        <div className="block pl-4 text-white bg-zinc-900 py-2 overflow-y-auto">
          <ResultPanel />
        </div>
      </Split>
    </div>
  );

  const renderStandardLayout = () => (
    <div className="h-[calc(100%-64px)]">
      <Split className="h-full splitHor" minSize={600} gutterSize={8}>
        <CompetitionMarkdown challenge={challenge} />
        <Split
          direction="vertical"
          minSize={[300, 200]}
          gutterSize={8}
          snapOffset={1}
          dragInterval={1}
        >
          <div className="block">
            {challenge?.[0] && (
              <CodeEditor
                key={challenge[0]._id}
                challenge={challenge[0]}
                submissionHistory={submissionHistory}
              />
            )}
          </div>
          <div className="pl-4 bg-zinc-900 text-white py-2 overflow-y-auto">
            <ResultPanel />
          </div>
        </Split>
      </Split>
    </div>
  );

  return (
    <DisplayNameCheck>
      <div className="h-[calc(100vh-64px)] bg-zinc-950">
        {showSpinner && <Spinner />}
        {showConfetti && <GingerbreadAnimation show={true} duration={3000} />}

        <SuccessNotification
          executionStatus={executionStatus}
          showNotification={showNotification}
          onClose={() => setShowNotification(false)}
        />

        {contestCompleted && renderConfetti()}

        {challenge?.[0]?.editorlessChallenge
          ? renderEditorlessLayout()
          : renderStandardLayout()}

        {challenge && (
          <CompetitionFooter
            challenge={challenge}
            type={tag}
            className="h-16"
          />
        )}
      </div>
    </DisplayNameCheck>
  );
};

export default AdventChallenge;
