import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaGift, FaTimes } from "react-icons/fa";

const AdventNotice = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-4 right-4 z-50 animate-fade-up">
      <div className="bg-zinc-800 border-2 border-blue-400 rounded-lg shadow-lg p-4 max-w-sm">
        <div className="flex justify-between items-start">
          <div className="flex items-center">
            <FaGift className="text-blue-400 text-xl mr-3 animate-bounce" />
            <h3 className="text-lg font-semibold text-white">
              Advent of Salesforce 2024!
            </h3>
          </div>
          <button
            onClick={() => setIsVisible(false)}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <FaTimes />
          </button>
        </div>
        <p className="mt-2 text-gray-300 text-sm">
          Join our daily coding challenges this December! Solve festive
          Salesforce problems and earn a spot on the leaderboard.
        </p>
        <Link
          to="/advent"
          className="mt-3 inline-flex items-center text-sm bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition-colors"
        >
          Start the Challenges
        </Link>
      </div>
    </div>
  );
};

export default AdventNotice;
